import {
  faBolt,
  faBullseyePointer,
  faPlug,
  faRocket,
  faSearchLocation,
  faSmile,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'

const bottomFeatures = [
  {
    heading: 'Easy Integrations',
    body: 'Instantly integrate with dozens of popular third party vendors that help your dealership get the job done.',
    icon: faPlug,
  },
  {
    heading: 'Unmatched Performance',
    body: 'We leverage the latest technology to deliver websites that outpreform every other website provider in the industry.',
    icon: faRocket,
  },
  {
    heading: 'Offers',
    body: 'Unique lead capture tool that allows you to convert existing website traffic into dealership visits through targeted campaigns.',
    icon: faBullseyePointer,
  },
  {
    heading: 'Unbeatable Search',
    body: 'Our search enables a near-instant access to your entire inventory. Deliver hundreds of results without refreshing the page.',
    icon: faBolt,
  },
  {
    heading: 'Consignment',
    body: 'Enable your website customers to sell their vehicle directly on your site without taking on any risk.',
    icon: faSmile,
  },
  {
    heading: 'Leading SEO',
    body: 'We monitor for hundrends of search engine ranking factors to ensure that your dealership will outperforms your local competition.',
    icon: faSearchLocation,
  },
]

export const ProductFeatureGrid: FC = () => {
  return (
    <div className="relative bg-white py-16">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-base font-semibold uppercase tracking-wider text-blue-600">
          Attract shoppers
        </h2>
        <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Everything you need to stand out in your market
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {bottomFeatures.map((feature) => {
              return (
                <div key={feature.heading} className="pt-6">
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-blue-500 p-3 shadow-lg">
                          <FontAwesomeIcon
                            className="text-xl text-white"
                            icon={feature.icon}
                          />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                        {feature.heading}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        {feature.body}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
