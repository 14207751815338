import Link from 'next/link'

import { posts } from '../pages/blog'

export default function PostsRow() {
  return (
    <div className="mx-auto max-w-md bg-white px-4 pb-20 pt-16 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-28 lg:pt-24">
      <div className="relative mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl">
        <div>
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Recent publications
          </h2>
          <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            Keep up with the latest news from the driverseat team
          </p>
        </div>
        <div className="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {posts.slice(0, 3).map((post) => (
            <div key={post.title}>
              <Link href={`/blog/${post.slug}`} className="mt-4 block">
                <p className="text-xl font-semibold text-gray-900">
                  {post.title}
                </p>
                <p className="mt-3 text-base text-gray-500">
                  {post.description}
                </p>
              </Link>

              <div className="mt-6 flex items-center">
                <div className="shrink-0">
                  <span className="sr-only">{post.author.name}</span>
                  <img
                    className="size-10 rounded-full"
                    src={post.author.imageUrl}
                    alt={post.author.name}
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">
                    {post.author.name}
                  </p>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime={post.datetime}>{post.date}</time>
                    <span aria-hidden="true">&middot;</span>
                    <span>{post.readingTime} read</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
