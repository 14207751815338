import { faChartLine, faSwatchbook } from '@fortawesome/pro-duotone-svg-icons'
import {
  faBolt,
  faSearch,
  faSwatchbook as faSwatchbookRegular,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import TextTransition, { presets } from 'react-text-transition'

import { Layout } from '../components/Layout'
import { MobileNav } from '../components/MobileNav'
import { Noticeable } from '../components/Noticeable'
import PostsRow from '../components/PostsRow'
import { ProductFeatureGrid } from '../components/ProductFeatureGrid'
import { useNavbar } from '../components/useNavbar'

const TEXTS = ['consumers', 'performance', 'speed', 'conversions']

const features = [
  {
    title: 'First impressions matter',
    body: 'It takes about a blink of an eye for users to form an opinion about your website that determines whether they’ll stay or leave.',
    image:
      'https://res.cloudinary.com/driverseat/image/upload/f_auto,fl_progressive:semi,c_limit,w_1280/screely-1614808911991_fembba.png',
    icon: faBolt,
  },
  {
    title: 'Intuitive page builder',
    body: 'Our drag and drop page builder is just as powerful as it is intuitive. In fact, we’re sure that you already know how to use it.',
    image:
      'https://res.cloudinary.com/driverseat/image/upload/f_auto,fl_progressive:semi,c_limit,w_1280/screely-1614808652699_c7pats.png',
    icon: faSwatchbookRegular,
  },
  {
    title: 'Instant inventory search',
    body: 'Leave slow inventory pages in the past with driverseat. Customers will find the car they’re looking for in milliseconds.',
    image:
      'https://res.cloudinary.com/driverseat/video/upload/v1644028264/search_new_jct8bk.mov',
    video: true,
    icon: faSearch,
  },
]

const logos = [
  {
    image:
      'https://res.cloudinary.com/driverseat/image/upload/f_auto,fl_progressive:semi,c_limit,w_1280/Stripe_wordmark_-_white_lg_q8zffy.png',
    alt: 'Stripe',
  },
  {
    image:
      'https://res.cloudinary.com/driverseat/image/upload/f_auto,fl_progressive:semi,c_limit,w_1280/342-3429274_horde-technology-react-js-project_cr9c92.png',
    alt: 'React',
  },
  {
    image:
      'https://res.cloudinary.com/driverseat/image/upload/f_auto,fl_progressive:semi,c_limit,w_1280/Twilio_logo_white_smrnja.png',
    alt: 'Twilio',
  },
  {
    image:
      'https://res.cloudinary.com/driverseat/image/upload/f_auto,fl_progressive:semi,c_limit,w_1280/Asset_1_4x_wdjgts.png',
    alt: 'Vercel',
  },
  {
    image:
      'https://res.cloudinary.com/driverseat/image/upload/f_auto,fl_progressive:semi,c_limit,w_1280/Asset_3_4x_mc2rzn.png',
    alt: 'Algolia',
  },
]

const Home = () => {
  const { links } = useNavbar()
  const [index, setIndex] = useState(0)
  const [ready, setReady] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [tabIndex, setTabIndex] = useState(-1)

  useEffect(() => {
    const intervalId = setInterval(() => setReady(true), 10)

    return () => clearTimeout(intervalId)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 3000)
    setTabIndex(0)

    return () => clearTimeout(intervalId)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(
      () =>
        setTabIndex((index) => {
          if (index === 2) return 0
          return index + 1
        }),
      10000,
    )
    return () => clearTimeout(intervalId)
  }, [tabIndex])

  return (
    <Layout
      title="Next generation dealership websites | Driverseat"
      description="The platform for the next generation of dealerships."
    >
      <div>
        <div className="bg-gray-50">
          <div className="relative overflow-hidden">
            <div
              className="absolute inset-y-0 size-full"
              aria-hidden="true"
            >
              <div className="relative h-full">
                <svg
                  className="absolute right-full translate-x-1/4 translate-y-1/3 sm:translate-x-1/2 md:translate-y-1/2 lg:translate-x-full"
                  width="404"
                  height="784"
                  fill="none"
                  viewBox="0 0 404 784"
                >
                  <defs>
                    <pattern
                      id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="404"
                    height="784"
                    fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"
                  />
                </svg>
                <svg
                  className="absolute left-full -translate-x-1/4 -translate-y-3/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
                  width="404"
                  height="784"
                  fill="none"
                  viewBox="0 0 404 784"
                >
                  <defs>
                    <pattern
                      id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="404"
                    height="784"
                    fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"
                  />
                </svg>
              </div>
            </div>
            <div className="relative pb-16 pt-6 sm:pb-24 ">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <nav
                  className="relative flex items-center justify-between sm:h-10 md:justify-center "
                  aria-label="Global"
                >
                  <div className="flex flex-1 items-center md:absolute md:inset-y-0 md:left-0">
                    <div className="flex w-full items-center justify-between md:w-auto">
                      <Link href="/">
                        <span className="sr-only">driverseat</span>
                        <img
                          className="h-8 w-auto rounded-md sm:h-10"
                          src="https://res.cloudinary.com/driverseat/image/upload/f_auto,fl_progressive:semi,c_limit,w_1280/black-logo.png"
                          alt="Home"
                        />
                      </Link>
                      <div className="-mr-2 flex items-center md:hidden">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center rounded-md bg-gray-50 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                          id="main-menu"
                          aria-haspopup="true"
                          onClick={() => setShowMobileMenu(true)}
                        >
                          <span className="sr-only">Open main menu</span>
                          <svg
                            className="size-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M4 6h16M4 12h16M4 18h16"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:flex md:space-x-10">
                    {links.map((link) => {
                      return (
                        <Link
                          href={link.href}
                          key={link.label}
                          className="font-medium text-gray-500 hover:text-gray-900"
                        >
                          {link.label}
                        </Link>
                      )
                    })}
                    <Noticeable />
                  </div>
                  <div className="hidden md:absolute md:inset-y-0 md:right-0 md:flex md:items-center md:justify-end">
                    <span className="inline-flex rounded-md shadow">
                      <Link
                        href="/schedule-demo"
                        className="inline-flex items-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-blue-600 hover:text-blue-500"
                      >
                        Schedule demo
                      </Link>
                    </span>
                  </div>
                </nav>
              </div>

              {showMobileMenu && (
                <MobileNav closeMenu={() => setShowMobileMenu(false)} />
              )}

              <div className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24 sm:px-6 lg:px-8">
                <div className="text-center">
                  <div
                    className={`transition ${
                      ready ? 'opacity-100' : 'opacity-0'
                    }`}
                  >
                    <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                      <span className="block">Modern dealership websites </span>
                      <span className="block">
                        built for{' '}
                        <TextTransition
                          inline
                          springConfig={presets.gentle}
                          direction="down"
                          className="text-blue-600"
                        >
                          {TEXTS[index % TEXTS.length]}
                        </TextTransition>
                      </span>
                    </h1>
                  </div>

                  <p className="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
                    Online shoppers demand performance. Our platform delivers
                    fast, accessible dealership websites that improve over time.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative pb-12">
              <div className="mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="space-y-4 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
                  {features.map((feature, index) => {
                    return (
                      <button
                        type="button"
                        key={feature.title}
                        className="cursor-pointer overflow-hidden rounded-md bg-gray-800 text-left transition hover:shadow-lg"
                        onClick={() => setTabIndex(index)}
                      >
                        <div className="p-6">
                          <div className="flex items-center space-x-3">
                            <FontAwesomeIcon
                              className="text-lg text-white"
                              icon={feature.icon}
                            />
                            <p className="text-xl font-medium leading-6 text-gray-100">
                              {feature.title}
                            </p>
                          </div>

                          <p className="mt-2 text-base text-gray-400">
                            {feature.body}
                          </p>
                        </div>
                        <div
                          className={
                            tabIndex === index
                              ? `h-2 w-full bg-gray-400`
                              : `h-2 w-0 bg-gray-400`
                          }
                          style={{
                            transition:
                              tabIndex === index
                                ? '10000ms width linear'
                                : 'unset',
                          }}
                        />
                      </button>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="relative">
              <div
                className="absolute inset-0 flex flex-col"
                aria-hidden="true"
              >
                <div className="flex-1" />
                <div className="w-full flex-1 bg-gray-800" />
              </div>
              <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                {features.map((feature, index) => {
                  if (feature.video) {
                    return (
                      <video
                        src={feature.image}
                        autoPlay
                        muted
                        loop
                        playsInline
                        key={feature.image}
                        className={`relative rounded-lg shadow-lg transition ${
                          index !== tabIndex && 'hidden'
                        }`}
                      />
                    )
                  }

                  return (
                    <img
                      key={feature.image}
                      className={`relative rounded-lg shadow-lg transition ${
                        index !== tabIndex && 'hidden'
                      }`}
                      src={feature.image}
                      alt="Feature"
                    />
                  )
                })}
              </div>
            </div>
          </div>
          <div className="bg-gray-800">
            <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
              <h2 className="text-center text-sm font-semibold uppercase tracking-wide text-gray-400">
                Built on the leading technology in the industry
              </h2>
              <div className="mt-8 grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
                {logos.map((logo) => {
                  return (
                    <div
                      key={logo.image}
                      className="col-span-1 flex justify-center opacity-75 md:col-span-2 lg:col-span-1"
                    >
                      <img className="h-10" src={logo.image} alt={logo.alt} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden bg-white py-32">
          <div className="relative">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
              <div className="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:px-0 lg:py-16">
                <div>
                  <div>
                    <span className="flex size-12 items-center justify-center rounded-md bg-blue-600">
                      <FontAwesomeIcon
                        className="text-2xl text-white"
                        icon={faChartLine}
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Keep an eye on what matters most
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      As data nerds, we understand the metrics that are most
                      important to you. With our real-time dashboard, you can
                      make informed decisions on the fly. Or you can monitor
                      your digital footprint with our weekly reports delivered
                      directly to your inbox.
                    </p>
                    <div className="mt-6">
                      <Link
                        href="/features"
                        className="inline-flex rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700"
                      >
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p className="text-base text-gray-500">
                        &ldquo;We're getting more leads than we know what to do
                        with!&rdquo;
                      </p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3">
                        <div className="text-base font-medium text-gray-700">
                          Mike Offer, General Manager at J.C. Lewis Ford
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                  <img
                    className="ring-opacity/5 w-full rounded-xl shadow-xl ring-1 ring-black lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="https://res.cloudinary.com/driverseat/image/upload/v1615499883/Screen_Shot_2021-03-11_at_4.57.22_PM_npijva.png"
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
              <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:px-0 lg:py-32">
                <div>
                  <div>
                    <span className="flex size-12 items-center justify-center rounded-md bg-blue-600">
                      <FontAwesomeIcon
                        className="text-2xl text-white"
                        icon={faSwatchbook}
                      />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      Easy to use visual editor
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      With driverseat you can create beautiful pages with no
                      hassle. Our drag and drop page builder is just as powerful
                      as it is intuitive. In fact, we’re sure that you already
                      know how to use it.
                    </p>
                    <p className="mt-4 text-lg text-gray-500">
                      Start with a blank canvas and quickly build landing pages,
                      model research pages, or anything else that your
                      dealership may need, and all without the help of creative
                      agencies.
                    </p>
                    <div className="mt-6">
                      <Link
                        href="/features"
                        className="inline-flex rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700"
                      >
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
                <div className="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                  <img
                    className="ring-opacity/5 w-full rounded-xl shadow-xl ring-1 ring-black lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="https://res.cloudinary.com/driverseat/image/upload/f_auto,fl_progressive:semi,c_limit,w_1280/Easy_to_use_CMS_mwfevr.png"
                    alt="Customer profile user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white py-16">
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="relative overflow-hidden rounded-xl bg-blue-500 px-8 py-24 shadow-2xl lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-16">
              <div className="absolute inset-0 opacity-50 mix-blend-multiply saturate-0">
                <img
                  src="https://res.cloudinary.com/driverseat/image/upload/v1649812108/driverseat/60b7c072e9074e21a5110154_nick.jpg"
                  alt="Headshot of Nick Kaptain"
                  className="size-full object-cover object-top"
                />
              </div>
              <div className="relative lg:col-span-1">
                <img
                  className="h-12 w-auto"
                  src="https://res.cloudinary.com/driverseat/image/upload/v1649812712/driverseat/5f7755a8ba0c61270e5281e3_quotible-logo-white.svg"
                  alt="Logo of Quotible"
                />
                <blockquote className="mt-6 text-white">
                  <p className="text-xl font-medium sm:text-2xl">
                    We have worked with every major website provider in
                    automotive, and without a doubt driverseat websites perform
                    better in every way. In a highly competitive industry, every
                    advantage matters and with driverseat you will get that
                    edge.
                  </p>
                  <footer className="mt-6">
                    <p className="flex flex-col font-medium">
                      <span>Nick Kaptain</span>
                      <span>Co-Founder, Quotible</span>
                    </p>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
        <ProductFeatureGrid />
        <PostsRow />
      </div>
    </Layout>
  )
}

export default Home
